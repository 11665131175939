export const criticalFonts = () => {
  return `
  @font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url(/fonts/CodeNextBold.woff2) format("woff2"),
  url(/fonts/CodeNextBold.woff) format("woff");
  font-weight: bold;
  font-style: 700;
}

@font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url(/fonts/CodeNextBook.woff2) format("woff2"),
  url(/fonts/CodeNextBook.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "CodeNext";
  font-display: swap;
  src: url(/fonts/CodeNextSemiBold.woff2) format("woff2"),
  url(/fonts/CodeNextSemiBold.woff) format("woff");
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: "CeraPro";
  font-display: swap;
  src: url(/fonts/cera-pro/Cera-PRO-Regular.woff2) format("woff2"),
  url(/fonts/cera-pro/Cera-PRO-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
  `
}
