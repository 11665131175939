//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LangToggle from "~/components/common/header/LangToggle";
import CurrencyToggle from "~/components/common/header/CurrencyToggle.vue";
import ProfilePageMixin from "~/node_modules/sitis-nuxt/lib/mixins/ProfilePageMixin";

export default {
  mixins: [ProfilePageMixin],

  data: function () {
    return {
      isFixedHeader: false,
      isOpenUserMenu: false
    }
  },

  computed: {
    isAuth: function () {
      return Boolean(this.$store.state?.user?.user?.id)
    },

    homePath: function () {
      const localeCode = this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";
      return `/${localeCode === 'en' ? '' : localeCode}`;
    },

    userBalance: function () {
      const _balance = this.$store.state["user-local"]?.balance || 0;
      const _currencyCode = this.$store.getters["currency/currentCurrency"];
      const _currencySymbol = Boolean(_currencyCode == 'rub') ? '₽' : '$';

      if (!this.isAuth) {
        return [this.$convertCurrency(0), _currencySymbol].join(' ')
      }
      return [this.$convertCurrency(_balance), _currencySymbol].join(' ');
    },
    userMenuItems: function () {
      return [
        {label: this.$t("profileNavMenu.activeNumber"), path: this.localePath('/profile'), icon: require('~/assets/img/svg/user-profile-navigation/received-numbers.svg')},
        {label: this.$t("profileNavMenu.queryStatistics"), path: this.localePath('/profile/statistics'), icon: require('~/assets/img/svg/user-profile-navigation/request-statistics.svg')},
        {label: this.$t("profileNavMenu.balanceHistory"), path: this.localePath('/profile/history'), icon: require('~/assets/img/svg/user-profile-navigation/balance-history.svg')},
        {label: this.$t("profileNavMenu.tariffs"), path: this.localePath('/profile/tariffs'), icon: require('~/assets/img/svg/user-profile-navigation/tariffs.svg')},
        {label: this.$t("profileNavMenu.settings"), path: this.localePath('/profile/settings'), icon: require('~/assets/img/svg/user-profile-navigation/settings.svg')},
        {label: this.$t("profileNavMenu.referralProgram"), path: this.localePath('/profile/referral-program'), icon: require('~/assets/img/svg/user-profile-navigation/referral-program.svg')},
      ]
    },
  },

  components: {
    CurrencyToggle,
    LangToggle
  },

  mounted: function () {
    document.getElementById('scroll-container').addEventListener("scroll", this.eventScroll);
  },
  destroyed: function () {
    document.getElementById('scroll-container').addEventListener("scroll", this.eventScroll);
  },

  methods: {
    eventScroll: function () {
      const scrollTop = document.getElementById('scroll-container').scrollTop || 0;
      if (scrollTop > 100 && !this.isFixedHeader) {
        this.isFixedHeader = true;
      }
      if (scrollTop <= 100 && this.isFixedHeader) {
        this.isFixedHeader = false;
      }
    },
    changeOpenUserMenu: function () {
      if (!this.isAuth) {
        this.$router.push(this.localePath('/authorization'));
        return
      }

      this.isOpenUserMenu = !this.isOpenUserMenu;
    },

    openSearch: function () {
      this.$emit('changeOpenSearch')
    },

    logout: async function () {
      this.userLogout();
      this.isOpenUserMenu = false;
      this.$router.replace(this.localePath("/"));
    },
  }
}
